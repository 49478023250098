import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Img from 'gatsby-image';
import { useScrollPosition } from '@/hooks/useScrollPosition';
import Container from '@/components/Base/Container/PageContainer';
import Layout from '@/components/Base/Layout';
import Links from '@/components/Base/Links';
import SEO from '@/components/Base/seo';

const ImgStyled = styled(props => <Img {...props} />)`
  &::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.mask};
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }
  @media ${({ theme }) => theme.breakpoints.mobile} {
    height: 15rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: 20rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    height: 30rem;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    height: 45rem;
  }
`;

const Heading = styled.h1`
  font-size: 20rem;
  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.mainText};
  text-shadow: 0px 0px 0 ${({ theme }) => theme.colors.blackText}, -1px -1px 0 ${({ theme }) => theme.colors.blackText},
    1px -1px 0 ${({ theme }) => theme.colors.blackText}, -1px 1px 0 ${({ theme }) => theme.colors.blackText},
    1px 1px 0 ${({ theme }) => theme.colors.blackText};
  font-weight: 600;
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 6rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 12rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 15rem;
  }
`;

const Gallery = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: -1rem;
`;

const GalleryItem = styled(props => <Links {...props} />)`
  flex: 1;
  padding: 1rem;
  text-decoration: none;
  position: relative;
  color: ${({ theme }) => theme.colors.fafafa};
  width: 85rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    width: 30rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 40rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 60rem;
  }
  @media ${({ theme }) => theme.breakpoints.bigscreen} {
    width: 90rem;
  }
  color: ${({ theme }) => theme.colors.blackText};
`;

const CardTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 1rem;
  left: 0;
  color: ${({ theme }) => theme.colors.alwaysWhite};
  :hover,
  :active,
  :focus {
    color: ${({ theme }) => theme.colors.whiteGray};
  }
  z-index: 10;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const CardTitle = styled.h2`
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  padding-bottom: 0.5rem;
  padding: 0 1rem;
  font-size: 2.5rem;
  line-height: 3rem;
  max-width: 50rem;
`;

const CardSubtitle = styled.h3`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0 1rem;
  text-align: center;
  @media ${({ theme }) => theme.breakpoints.touch} {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  max-width: 50rem;
`;
const initialCounter = 6;
const ArticlePage = ({ pageContext }) => {
  const { posts: allPosts } = pageContext;
  const [counter, setCounter] = useState(initialCounter);
  const [posts, setPosts] = useState(allPosts.slice(0, initialCounter));
  useScrollPosition(
    ({ currPos }) => {
      const fullOffset =
        document.documentElement.scrollHeight -
        document.documentElement.getElementsByTagName('footer')[0].clientHeight -
        document.body.clientHeight;
      const isShow = -currPos.y >= fullOffset;
      if (isShow) {
        const newCounter = counter + initialCounter;
        setPosts([...posts, ...allPosts.slice(counter, newCounter)]);
        setCounter(newCounter);
      }
    },
    [posts, counter],
    undefined,
    undefined,
    100
  );

  const pageTitle = 'Тексты';
  return (
    <Layout>
      <SEO title={pageTitle} keywords={[pageTitle]} slug="articles" />
      <Container>
        <Heading>{pageTitle}</Heading>
        <Gallery>
          {!!posts &&
            posts.map(({ title, subtitle, slug, image }) => {
              return (
                <GalleryItem key={slug} to={`/articles/${slug}`}>
                  <ImgStyled fluid={image} />
                  <CardTextBlock>
                    <CardTitle>{title}</CardTitle>
                    <CardSubtitle>{subtitle}</CardSubtitle>
                  </CardTextBlock>
                </GalleryItem>
              );
            })}
        </Gallery>
      </Container>
    </Layout>
  );
};

ArticlePage.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default ArticlePage;
